import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import ReactOnRails from 'react-on-rails';
import axios from 'axios';
import "@hotwired/turbo-rails"
import "../controllers"

// Imports
import '@fortawesome/fontawesome-svg-core/styles.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { CustomDarkTheme } from '../components/landingpage/theme.jsx';
import '../styles/application.css';

// Components
import OpenAIChatbot from '../components/openAiChatbot.jsx';
import FormFinder from '../components/FormFinder.jsx';
import LandingPage from '../components/landingpage/landingPage.jsx';
import AmandasPage from '../components/AmandasPage.jsx';
import PaperWorker from '../components/PaperWorker.jsx';
import SignIn from '../components/SignIn.jsx';

// CSRF Setup
const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
if (csrfToken) {
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
}

// Wrapper Components
const withProviders = (Component) => (props) => (
  <Router>
    <ThemeProvider theme={CustomDarkTheme}>
      <CssBaseline />
      <Component {...props} />
    </ThemeProvider>
  </Router>
);

// Initialize React components
document.addEventListener('DOMContentLoaded', () => {
  const mountPoints = document.querySelectorAll('[data-react-component]');
  mountPoints.forEach(mountPoint => {
    const componentName = mountPoint.dataset.reactComponent;
    const props = JSON.parse(mountPoint.dataset.props || '{}');
    
    if (ReactOnRails.registeredComponents[componentName]) {
      const Component = ReactOnRails.registeredComponents[componentName];
      const root = createRoot(mountPoint);
      root.render(<Component {...props} />);
    }
  });
});

// Register components with ReactOnRails
ReactOnRails.register({
  LandingPage: withProviders(LandingPage),
  OpenAIChatbot: withProviders(OpenAIChatbot),
  FormFinder: withProviders(FormFinder),
  AmandasPage: withProviders(AmandasPage),
  PaperWorker: withProviders(PaperWorker),
  SignIn: withProviders(SignIn),
});

// Initialize Turbo
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
